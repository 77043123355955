import React, {useContext} from 'react';
import {styled} from 'linaria/react';
import {theme} from '../../Theme';
import {useQuery} from 'react-apollo';
import categoryContentQuery from '../../CustomContent/CategoryContentQuery.gql';
import {ContentRenderer} from '@jetshop/ui/ContentRenderer';
import contentComponents from '../../CustomContent/elementMap';
import OuterMaxWidth from "../OuterMaxWidth";
import {Above} from "@jetshop/ui/Breakpoints";
import ChannelSelector from "./ChannelSelector/ChannelSelector";
import ChannelContext from "@jetshop/core/components/ChannelContext/index";


const TopBarContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  position: relative;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      color: ${theme.colors.main};

      + li::before {
        content: '\u25E6';
        margin: 0 1rem;
        font-size: 26px;
      }

      a {
        color: inherit;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        font-size: 14px;
      }

    }

  }

`;


const ChannelSelectorWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  background: ${theme.colors.black};
  border-radius: 50rem;
  transition: 0.1s;

  &:hover {
    background: #666;
  }

  > button {
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;

    svg {
      fill: #fff;
    }

  }



`

const Wrapper = styled('section')`
  background: ${theme.colors.background};





`;


const TopBar = () => {
  const {selectedChannel} = useContext(ChannelContext);
  const channelName = selectedChannel.name;
  const contentQuery = useQuery(categoryContentQuery, {
    variables: {
      id: theme.settings.topBarUspCategoryId
    }
  });
  const allUspItems = contentQuery.data?.category?.data?.items;
  const filteredUspItems = (allUspItems && allUspItems.length) ? allUspItems.filter((item) => {
    const intendedChannel = item.properties?.[0].value?.value;
    return intendedChannel && (intendedChannel === channelName || intendedChannel === "ALL");
  }) : null;

  return (
    <Wrapper>
      <OuterMaxWidth>
        <TopBarContent>
          {filteredUspItems && filteredUspItems.length && (
            <ContentRenderer
              items={[filteredUspItems[0]]}
              components={contentComponents}
            />
          )}
          {channelName !== "B2B" && (
            <Above breakpoint={'lg'}>
              <ChannelSelectorWrapper>
                <ChannelSelector/>
              </ChannelSelectorWrapper>
            </Above>
          )}
        </TopBarContent>
      </OuterMaxWidth>
    </Wrapper>
  );
};

export default TopBar;
